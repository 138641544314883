export default {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  LIGHT: 'light',
  DARK: 'dark',
  WHITE: 'white',
  BLACK: 'black',
  LINK: 'link',
};
